<template>

        <div class="menublok_basis">


                    <div 
                          v-on:click="wisselMenuStatus ( )"

                          v-bind:class="[ isOpenButton (  ) ]"  
                          ></div>

                         
                           <div class="logo_blok">
                                      <img class="logo mobiel"  src="@/assets/logo-bas-klein.png" />
                                      <img class="logo desktop"  src="@/assets/logo-bas.png" />
                           </div>
            </div>




            <div 
                  v-bind:class="[ isOpen (  ) ]"  
                  >

                   <router-link to="/">{{vertaal( 'schilderijen' )}}</router-link>
                   <router-link to="/atelier">{{vertaal( 'atelier' )}}</router-link>
                   <router-link to="/over">{{vertaal( 'over' )}}</router-link>
                   <router-link to="/contact">{{vertaal( 'contact' )}}</router-link>


            <div 
                  v-on:click="wisselTaal ( 1 )"

                  v-bind:class="[ isTaal (  1 ) ]"  
                  >Nederlands</div>

            <div 
                  v-on:click="wisselTaal ( 2 )"

                  v-bind:class="[ isTaal ( 2 ) ]"  
                  >English</div>


              </div>



</template>





<script>

/*


                 <img class="logo mobiel"  src="@/assets/logo-bas-klein.png" />



<div class="logo"  srcx="@/assets/logo-bas.png" ></div>

<h1>Bas Lobik #paintings</h1>

*/




import { 
  reactive, 
  computed 
} from 'vue';

import store from '@/store'




export default {

    name: 'Menu',

    // props: {

    //      //   selectie: String,
    // },

    components: {
      //      LoadingImg
          
    },



    setup ( ) {

        const event = reactive ({

                      taal : 1, // nl 

                      menu_open: false,


                  taalkeuze: computed (() => { 

                            return  store.getters.getTaalKeuze();
                  }),


                    menuStatus: computed (() => {

                       //alert ( store.state.menu_status);

                        return store.state.menu_status;
                }),


                activeClass: 'lijst_select',
                standClass: '',
        });



         function vertaal ( code ) {

                let t =  store.getters.getVertaling ( code );

                console.log( "Vertaal: " + code + " - " + t   );          

                return t;
         }



            function isOpenButton ()
            {
                    if ( event.menuStatus== 'open'  ) {

                          return "menu_button actief";
                    }

                   return "menu_button ";
            }


            function isOpen ()
            {
                    if ( event.menuStatus == 'open'  ) 
                    {
                          return "menu_blok menu_open";
                    }
                    else if ( event.menuStatus == 'dicht'  ) 
                    {
                        return "menu_blok menu_dicht";

                    }

                   return "menu_blok";
            }





            function wisselMenuStatus ()
            {

                    store.dispatch ( 'wisselMenuStatus' );
            }



            function wisselTaal ( taal )
            {
                    store.dispatch ( 'setTaalKeuze' , taal );
                    store.dispatch ( 'setMenuStatus', 'close' );   
            }




            function isTaal ( taal )
            {
                    if ( event.taalkeuze == taal  ) 
                    {
                          return "taal select";
                    }

                   return "taal";
            }


            function initPaginaData () {

                    store.dispatch ( 'setMenuStatus', 'close' );   
                    store.dispatch ( 'laadTekst' );   
                                        store.dispatch ( 'laadVertaling' );   

            }




        return { 
              event, 
             wisselTaal,
             isTaal,
             wisselMenuStatus,
             isOpen,
             isOpenButton,
             vertaal,
             initPaginaData,
        };
    },




          mounted () {

                //event.menu_open = false;
           //     console.log ( "kunstwerkenLayout mounted " );


                this.initPaginaData ( );

              // console.log( "AdresPagina: updated" );

               //event.imgIsLoaded = false;
          },



          updated () {

               // event.menu_open = false;


             //   console.log ( "kunstwerkenLayout updated " );

              // this.initPaginaData ( false );

              // console.log( "AdresPagina: updated" );

               //event.imgIsLoaded = false;
          },

}


</script>












<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>


.menublok_basis 
{
          margin: 0 1rem 0 1rem;
          padding:  0;

          height:  6rem;

          text-align: left;

        display:  grid;

        grid-template-columns: 2rem 1fr;
        grid-gap: 1rem;   
}



@media (min-width: 27rem) 
{
          .menublok_basis 
          {
                margin: 0 1rem 0 2rem;
        }
}





.menu_button 
{
          display: block;

          margin:  0;
          padding: 0;

          justify-self: center;
          align-self: center;

          width:  3rem;
          height:  3rem;


          background-image: url( ~@/assets/menu-bas.png ); 
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 2.4rem 2.4rem;     
}



.menu_button.actief
{
          background-image: url( ~@/assets/menu-bas-actief.png ); 

/*          animation: schuif_in linear 1s;
         animation-timing-function: ease;
         animation-iteration-count: 1;
         transform-origin: 50% 50%;
         animation-fill-mode: forwards;    */
}





.logo_blok 
{
            justify-self: left;
          align-self: center;
}



img.logo
{
          display: block;
          max-width:  20rem;
          width:  100%;

          margin: 0;
          padding:  0;


/*          background-image: url( ~@/assets/logo-bas-klein.png ); 
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100%;    */
}


.logo.mobiel
{
        max-width:  10rem;
}


.logo.desktop
{
        display:  none;
}


@media (min-width: 27rem) 
{
            .logo
            {
  
                      margin: 0;
                      padding:  0;

/*                      background-image: url( ~@/assets/logo-bas.png ); 
                      background-repeat: no-repeat;
                      background-position: center center;
                      background-size: 100%;    */
            }

            .logo.desktop
            {
                    display:  block;
                    max-width:  20rem;
            }


            .logo.mobiel
            {
                    display:  none;
            }


}








h1
{
          display: inline-block;
          margin: 0;
          padding:  0;

            font-family: 'Source Sans Pro', sans-serif;

          font-weight: 200;
          font-size: 2.2rem;
          line-height: 1.25em;

          text-align: left;
}







.menu_blok
{
          margin:  0;
          padding: 0;

          z-index: 100;

          width:  14rem;
          position: absolute;

          left:  -14rem;

         opacity: 0;


         text-align: right;

         background-color: rgb( 255, 100, 100 );

}





.menu_blok.menu_dicht
{
        left:  -14rem;
/*          opacity: 0;
*/

  -webkit-animation: schuif_uit  0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: schuif_uit 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;


/*         animation: schuif_uit linear 1s;
         animation-timing-function: ease;
         animation-iteration-count: 1;
        animation-fill-mode: forwards;   */
}





.menu_blok.menu_open
{
         animation: schuif_in linear 1s;
         animation-timing-function: ease;
         animation-iteration-count: 1;
/*         transform-origin: 50% 50%;
*/         animation-fill-mode: forwards;     
}






.menu_blok  a 
{
        display:  block;

        padding:  .5rem 1rem;

        font-size: 1.2rem;
        line-height: 1.8em;

        font-weight: 600;

        text-decoration: none;
}




/*.menu_blok .hoverclass::hover
{
      color: rgb( 255, 255, 255 );
      background-color: rgb( 255, 90, 90 );
}*/


.menu_blok a
{
        color: rgb( 255, 200, 200 );
        

}


.menu_blok a:hover 
{
/*        background-color: rgba(255, 112, 112, 0.8);*/

    color: #fff;

        padding-right: 1.5rem;
        margin-right: -.5rem;

        border-top-right-radius: .5rem;
        border-bottom-right-radius: .5rem;
}


.menu_blok a.router-link-exact-active 
{
        color: #fff;
}





.taal 
{
      display: inline-block;

      padding:  .5rem 1rem;

      color: rgb( 255, 200, 200 );
}

.taal.select
{
      color: #fff;
      background-color: rgb( 255, 150, 150 );
}



@keyframes schuif_in {

       0% {
                left:  -14rem;
               opacity: 0;
      }

        80%  {

                    opacity: .5;
                    left:  0;
        }  

        100%  {

                    left:  0;
                    opacity: 1;
        }  
}

@keyframes schuif_uit {

       0% {        
                left: 0;
               opacity: 1;
     }

        100% {            
                    left:  -14rem;
                   opacity: 0;
              }  

}









</style>
