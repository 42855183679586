
import { createRouter, createWebHashHistory } from 'vue-router'
import PaginaSchilderijen from '../views/PaginaSchilderijen.vue'

const routes = [
  {
         path: '/',
         name: 'Schilderijen',
         component: PaginaSchilderijen
  },
  {
        path: '/atelier',
        name: 'Atelier',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import ( '../views/PaginaAtelier.vue' )
  },
  {
        path: '/over',
        name: 'Over',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import ( '../views/PaginaOver.vue' )
  },
    {
        path: '/contact',
        name: 'Contact',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import ( '../views/PaginaContact.vue' )
  }
]



const router = createRouter({
      history: createWebHashHistory(),
      routes
})


export default router
