<template>

      <div  
            v-if="event.loading">
            <p>
              Laden {{ vertaal ( 'site_naam' ) }}
            </p>

      </div>

      <div 
          v-else 
          class="content_schilderijen"
          >

          <div class="blok_mobiel">

               <div 
                      v-if="event.aantal_beelden > 0 "
                      class="fotoblok_paintings">


                               <div 
                                               v-for="(value_beeld, key_beeld) in event.beeld" 
                                               :key="key_beeld" 
                                          >

                                          <ImageItem 
                                                   :source="value_beeld.img"  
                                                   :bijschrift="value_beeld.tekst [ event.taalkeuze ] !== null  ? value_beeld.tekst [ event.taalkeuze ] : '' "  
                                          />

                                          <div class="tussenstukje"></div>

                                  </div>
                       </div>
          </div>



          <div class="blok_desktop">

            <div 
                  class="beeld_navigatie">
                         <div 
                                v-on:click="wisselBeeld ( event.beeld_nr - 1 )"
                                class="button vorige" > 
                              </div> 


                          <div                              
                                v-on:click="wisselBeeld ( event.beeld_nr + 1 )"
                                class="button volgende" >
                              </div>

            </div>


               <div 
                      v-if="event.aantal_beelden > 0 "
                      class="fotoblok_paintings">


                               <div 
                                               v-for="(value_beeld, key_beeld) in event.beeld" 
                                               :key="key_beeld" 
                                          >

                                          <ImageItem 
                                                  v-if="key_beeld == event.beeld_nr"
                                                   :source="value_beeld.img"  
                                                  :bijschrift="value_beeld.tekst [ event.taalkeuze ] !== null  ? value_beeld.tekst [ event.taalkeuze ] : '' "  

                                          />

                                  </div>
                       </div>
                    </div>






             </div>



</template>



<script>

/*


                                                            {{event.beeld_nr}}

                          <ImageItem 
                                   :source="event.beeld [ event.beeld_nr ].img"  
                                   :bijschrift="event.beeld[ event.beeld_nr ].tekst [ event.taalkeuze ] !== null  ? event.beeld[ event.beeld_nr ].tekst [ event.taalkeuze ] : '' "  
                          />



          <ImageItem 
                   source="http://baslobik.local/app/src/assets/f1a.jpg"  
                   bijschrift="test"  
            />



                                                <ImageItem 
                                                         :source="value_beeld.img"  
                                                         :bijschrift="value_beeld.tekst [ event.taalkeuze ] !== null  ? value_beeld.tekst [ event.taalkeuze ] : '' "  
                                                />
*/



import ImageItem from '@/components/ImageItem.vue'




import { reactive, computed }  from 'vue';
import store from '@/store'


export default {

    name: 'Schilderijen',

    // props: {

    //       //  kop: {      
    //       //      // required: true,     
    //       //       type: String,
    //       //       default: "",    
    //       // },

    //       //  doelgroep: {      
    //       //      // required: true,     
    //       //       type: String,
    //       //       default: "0",    
    //       // },

    // },

    components: {
            ImageItem
    },



    setup ( ) {

        const event = reactive ({

                tekst_id: 0,
                beeld_nr: 0,


               // aantal_beelden: 0,


                taalkeuze: computed (() => { 

                          return  store.getters.getTaalKeuze();
                }),



               tekst: computed (() => { 

                       let a =  store.getters.getTekst( 1 );

                       //console.log ( "tekst: "  + a );


                       if ( a == null ) return  { 'naam' : 'loading' , 'body' : 'loading' };

                       return a;
                }),


               beeld: computed (() => { 

                       let a =  store.getters.getTekst ( 1 );

                       //console.log ( "tekst: "  + a );
                    //   event.aantal_beelden = 0;

                       if ( a === undefined ) return null;

                      // console.log( "setTekstData: "  + JSON.stringify ( a ) )

                       if ( a.beeld            === undefined ) return null;
                       if ( a.beeld [ 0 ]   === undefined ) return null;


                       let c = a.beeld;


                       // console.log( "Beeld: "  + JSON.stringify ( c ) )


                       c.sort( ( a, b ) => {

                              let x = parseInt ( a.volgnr  );
                              let y = parseInt ( b.volgnr  );

                              if (  x < y ) {
                                      return -1;
                              }

                              if ( x > y ) {
                                      return 1;
                              }

                              // names must be equal
                              return 0;
                       });


                       return c;
                }),



               aantal_beelden: computed (() => { 

                       let a =  store.getters.getTekst ( 1 );

                       //console.log ( "tekst: "  + a );
                    //   event.aantal_beelden = 0;

                       if ( a === undefined ) return null;
                       if ( a.beeld  === undefined ) return null;

                       let t = a.beeld.length ;


                       return t;
                }),





              loading: computed (() => { 

                      return ( store.getters.getTekstStatus ()  ) 
              }), 



        });


        function wisselBeeld (  i )
        {
              //alert ( i );

                if ( i < 0 )
                {
                        i = event.aantal_beelden - 1;
                }

                if ( i >= event.aantal_beelden )
                {
                      i = 0;
                }

                event.beeld_nr = i ;
        }




         function setTekstKeuze ( data ) 
         {
                  event.tekst_id     = data.id;
                 // event.tekst            = data.body [ store.taalKeuze ];
         }


        function vertaal ( code ) {

                return store.getters.getVertaling ( code );
        }



         return { 
                event, 
                vertaal,
        //        laadProjectTekst, 
                setTekstKeuze ,
                wisselBeeld,
              };
     },




      mounted() {

                 store.dispatch ( 'setMenuStatus', 'close' );
           //     this.laadProjectTekst();
      },


      updated() {

             //   this.laadProjectTekst();
      },

}



</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>





.content_schilderijen 
{

}


.blok_mobiel 
{
      display: block;
}

.blok_desktop 
{
      display: none;
}




.fotoblok_paintings 
{
      text-align: center;

      height:  calc( 100vh - 10rem ); 
}



.fotoblok_paintings img
{
      max-height:  calc( 100vh - 15rem );      
      max-width:  calc( 100vw - 2rem );
}





.beeld_navigatie 
{

          text-align: center;
}


.button 
{
         display: inline-block;
         width: 2.5rem;
         height:  2.5rem;

         margin:  .25rem 0 0 .5rem;

         border:  1px solid rgb( 240, 240, 240 );

         border-radius: 5px;

         background-image: url( ~@/assets/but-vorige.svg ); 
         background-repeat: no-repeat;
         background-position: center center;
         background-size: 2rem 2rem;            
}


.button.vorige
{

}

.button.volgende
{
    background-image: url( ~@/assets/but-volgende.svg ); 
}



.button:hover
{
      background-color: rgb( 240, 240, 240 );
}




.tussenstukje 
{
      height:  4rem;
}

@media (min-width: 30rem) 
{

          .beeld_navigatie 
          {
                margin-top: -1rem;
          }

          .blok_mobiel 
          {
                display: none;
          }

          .blok_desktop 
          {
                display: block;
          }


}







</style>
