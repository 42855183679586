<template>

     <Menu />

     <router-view />

</template>

<script>




  
// @ is an alias to /src
import Menu from '@/components/Menu.vue'

export default {
      name: 'BasLobik',
      components: {
        Menu
      }
}
</script>




<style>


@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400;600&display=swap');

body 
{
      margin:  0;
      padding:  0;
}



#app {
        font-family: Avenir, Helvetica, Arial, sans-serif;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;


        font-family: 'Source Sans Pro', sans-serif;

        font-size: 1rem;
        line-height: 1.25 rem;

        text-align: left;
        color: rgb( 120, 120, 120 );

        margin:  0;
        padding:  0;
}



h1
{
        text-align: left;

        font-weight: 400;
}

article ,
p
{
         margin:  0;
        padding:  0;     
}




a {

  color:  rgb( 90, 90, 90 );
}


</style>
