import { createStore } from 'vuex'



let siteImagesUrl = 'https://baslobik.nl/app_images_01/' ;
let appDataUrl = '/app-data/index.php' ;


if ( process.env.NODE_ENV !== 'production'  )
{
        siteImagesUrl   = 'http://baslobik.local/app_images_01/' ;
        appDataUrl             = 'http://baslobik.local/app-data/index.php' ;

      //alert ( 'local' );
}



export default createStore({

  state: {

                  siteImagesUrl : siteImagesUrl ,

                  appDataUrl : appDataUrl,


                     siteVersie: { 
                            status: false, 
                            t: '', 
                            v: '',
                      },

                  menu_status: '',

                 taalKeuze: 0,

                 vertaalLijst : [],

                 vertaling_busy: false,

                 tekstData : [],
                 tekstKeuze: 0,
                 tekst_busy: false,

  },


  mutations: {


            //----------------------------------------------//


              setSiteVersie ( state, data_geladen ) {


                    let versie = 0;


                      if ( typeof data_geladen !== "undefined" )
                      {
                              if ( typeof data_geladen.data !== "undefined" )
                              {
                                        if ( typeof data_geladen.data.versie !== "undefined" )
                                        {
                                              versie = data_geladen.data.versie;
                                        }
                              }     
                      }


                      //-----------------------------------------------------//
                      // data versie geladen - hetzelfde gebleven dus klaar
                      //-----------------------------------------------------//


                       if ( localStorage.getItem ( 'versie' ) == versie  )
                       {
                               // this.loading = false;

                                state.siteVersie.v              =  versie;
                             //   state.siteVersie.t               = data_geladen.t ;  // tijd
                                state.siteVersie.status   = true ;

                                return;
                       }
     

                      //-----------------------------------------------------//
                      // data niet hetzelfde - opslaan en reload pagina
                      //-----------------------------------------------------//


                        state.siteVersie.v = versie;

                        localStorage.setItem ( 'versie', versie );

                        state.siteVersie.status   = true ;



                        //alert ( 'updaten scherm' );

                        console.log ( "updaten browser window" );



                         window.location.reload ( true );



                          navigator.serviceWorker.register('/sw.js').then(reg => {
                        // sometime later…
                                  console.log ( "updaten service worker" );
                                   reg.update();
                          });


                      //store.commit ( 'setEmail', localStorage.getItem ( 'email' ));

                      //-----------------------------------------------------//


              },


                setMenu ( state, menu_status ) {

                      this.state.menu_status = menu_status;
              },


            setVertaalLijst ( state, data ) {

                    state.vertaalLijst = data;
            },


            setTaalKeuze ( state, id ) {

                    state.taalKeuze = id;

                    localStorage.setItem ( 'taal_keuze', id );
            },



            setTekstData ( state, data ) {

                    state.tekstData = data;

                  // console.log( "setTekstData: "  + JSON.stringify ( state.tekstData ) )
            },


            setTekstKeuze ( state, data ) {

                    state.tekstKeuze = data;
            },




  },




    getters: {

             getTaalKeuze: state => ( ) => {

                   if ( state.taalKeuze == 0 )  
                  {
                            let t = localStorage.getItem ( 'taal_keuze' );

                            if ( t > 0  )
                            {
                                    state.taalKeuze = t;

                                    return t;
                            }

                         return 1;
                  }

                    return state.taalKeuze;
             },




             getVertalingVelden: state => ( code ) => {

                    if ( state.vertaalLijst != '' ) {
                        
                            let a = state.vertaalLijst.find ( record => record.code == code )

                            if ( a == null ) return [ '?' ];

                            if ( a.naam === undefined ) return [ '?' ];

                            //alert ( 'Buttons: ' + a.naam );

                            let b = [ '',  '',  '',  '',  '', ]
                            
                            b [ 0 ] = a.naam;
              //              b [ 1 ] = a.naam_d;
                            b [ 1 ] = a.naam_e;
                            // b [ 3 ] = a.naam_f;

                            //return a ;
                            return b;
                    }

                    return [ '?' ];
            },




             getVertaling: state => ( code ) => {

                    if ( state.appDataUrl != '' ) {
                        

                        if ( state.vertaalLijst.length == 0 ) {

                            console.log( "store getVertaling:  leeg - code = : "  + code  )

                            return code;
                      }


                        console.log( "store getVertaling: "  + state.vertaalLijst[ 1 ].naam )


                        let a = state.vertaalLijst.find ( record => record.code === code )

                        if ( a == null ) return code;

                        if ( a.naam == undefined ) return code;

                        if ( state.taalKeuze == 0 )  return a.naam;
                        if ( state.taalKeuze == 1 )  return a.naam;
                        if ( state.taalKeuze == 2 )  return a.naam_e;
                        // if ( state.taalKeuze == 3 )  return a.naam_d;
                        // if ( state.taalKeuze == 4 )  return a.naam_f;
                    }

                    return code ;
             },







                 // projectLijst : [],
                 // projectKeuze: 1,
                 // projectKeuzeData: null,


             getTekstStatus: state => ( ) => {

                          //console.log( "getTekstStatus:  lengte: "  + state.tekstData.length );

                        if ( state.tekstData.length > 0 ) return false;

                        return false;
             },




             getTekst: state => ( code ) => {

                       //let a = state.tekstData.find ( c => c.id == code )

                       // console.log( "getTekst: "  + code + " - " + JSON.stringify ( state.tekstData ) );

                        let a = state.tekstData.filter ( c => c.id == code );

                       //console.log( "getTekst: "  + code + " - " + JSON.stringify ( a ) );

                      //  console.log( "getTekst: "  + state.tekstData [ 0 ].naam [ 1 ] )


                       if ( a == null ) return {  'naam' : 'loading' , 'body' : 'loading' } ;

                       // if ( a.naam.length < 1 ) return code;

                        return a [ 0 ];
             },




            //  getTekstActueel: state => ( doelgroep, tekst_id ) => {

            //           if ( state.projectTekst.length == 0 )
            //           {
            //                  return '';
            //           }


            //           //-------------------------------------------//
            //           // zoek de tekst op
            //           //-------------------------------------------//


            //       //  let taalkeuze = this.state.getTaalKeuze()

            //         //alert ( "taal: " + taalkeuze )

            //          if ( state.taalKeuze == 0 )  state.taalKeuze = 1



            //           let a = state.projectTekst;

            //           let d = a.filter ( c => c.project_id == state.projectKeuze );

            //            let e = d.filter ( c => c.doelgroep == doelgroep );



            //            if (  tekst_id > 0  ) // kijk of de tekst ertussen staat
            //            {
            //                      let f = e.filter ( c => c.id == tekst_id );

            //                       if ( f [ 0 ]  !== undefined )
            //                       {
            //                               // alert ( 'tekst aanwezig: ' + f[0].id ) ;                          

            //                                return  f [ 0 ].body [ state.taalKeuze ];
            //                       }
            //             }


            //            //-------------------------------------------//
            //            // niet gevonden, stel in op de eerste in de lijst
            //            //-------------------------------------------//

            //            // alert ( 'tekst == 0 ');


            //          let g = e.filter ( cg => cg.body [ state.taalKeuze ] != '' );

            //           if ( g [ 0 ]  !== undefined )
            //           {
            //                   // alert ( 'tekst aanwezig: ' + f[0].id ) ;                          

            //                    return  g [ 0 ].body [ state.taalKeuze ];
            //           }

            //             if ( g [ 0 ]  !== undefined )
            //             {
            //                //   alert ( 'eerste tekst: ' + e[0].id ) ;

            //                      if ( g [ 0 ].id  !== undefined )
            //                      {
            //                              //tekst_id = e [ 0 ].id;

            //                              return  g [ 0 ].body [ state.taalKeuze  ];                             
            //                      }
            //             }

            //              return '-';
            // },




            // getRedactieFotos: state => ( code ) => {

            //         // if ( state.appDataUrl != '' ){

            //             let a = state.redactieTekstLijst.find ( record => record.naam === code )

            //             if ( a == null ) return [];

            //             if ( a.naam == undefined ) return [];

            //             return a.beeld;
            // },
    },




  actions: {


                    //----------------------------------------------//


                     async laadSiteVersie ( context ) {

                                //alert ( 'laad laadTekst' );

                                if ( this.state.siteVersie.status   )
                                {
                                   // alert ( 'vertaling is al geladen' );

                                     ////console.log( "laadSiteVersie is al geladen" );

                                    return;  // is al geladen
                                }

                                 console.log( "laadSiteVersie en basisinstellingen" );

                                this.state.siteVersie.status = false;



                               //------------------------------------------//
                               // taalkeuze herinneren
                               //------------------------------------------//


                                let tk = localStorage.getItem ( 'taal_keuze' );

                                if ( tk > 0 )
                                {
                                        context.commit ( 'setTaalKeuze', tk );
                                }


                               //------------------------------------------//


                                let url = this.state.appDataUrl + '?t=versie';

                               ///alert ( url );

                                let res     = await fetch ( url  );
                                let data  = await res.json();


                                context.commit ( 'setSiteVersie', data );


                                //context.commit ( 'setVoorkeuren' ); // favorieten

                                ////console.log ( "SiteVersie - geladen" );

                                //if ( this.state.tekstKeuze > 0 ) context.commit ( 'setTekstKeuze',  this.state.tekstKeuze );

                               //------------------------------------------//
                      },






              async  setMenuStatus ( context, menu_status ) 
              {
                    ///alert ( 'wisselMenuStatus' );
                      context.commit ( 'setMenu', menu_status )
              },   


             async wisselMenuStatus ( context ) 
             {

                    if ( this.state.menu_status == 'open' )
                    {
                        context.commit ( 'setMenu', 'dicht' )
                    }
                    else
                    {
                        context.commit ( 'setMenu', 'open' )
                    }
            },   




            async laadVertaling ( context ) {

                       // alert ( 'laad vertaling' );

                        if ( this.state.vertaalLijst.length > 0   )
                        {
                                console.log( 'vertaling is al geladen' );

                                 return;  // is al geladen
                        }


                        if ( this.state.vertaling_busy )  return;

                        this.state.vertaling_busy = true;



                       //------------------------------------------//


                        let url = this.state.appDataUrl + '?t=vertaling';

                     //   alert ( url )

                        let res = await fetch ( url  );

                        let data = await res.json();

                        context.commit ( 'setVertaalLijst', data );

                         // console.log( "tekst: "  + JSON.stringify ( data ) );



                       //------------------------------------------//
            },



             setTaalKeuze ( context, id ) {

                     context.commit ( 'setTaalKeuze',  id );
            },





          async laadTekst ( context ) {

                        // de teksten die aan een project hangen

                        if ( this.state.tekstData.length > 0  )
                        {
                               console.log( "laadTekst al geladen" );

                                return;
                        }


                        if ( this.state.tekst_busy )  return;

                        this.state.tekst_busy = true;

                       //------------------------------------------//


                        let url = this.state.appDataUrl + '?t=tekst';

                        let res = await fetch ( url  );

                        let data = await res.json();

                        context.commit ( 'setTekstData', data );

                       //alert ( 'geladen: setTekstLijst' );

                        console.log( "laadTekst - geladen" );

                        //console.log( "tekst: "  + data[0].naam[1] );


                       //------------------------------------------//
                  },




                // setTekstKeuze ( context, id ) {
        

                //         // test eerst of deze er wel is

                //         let d = this.state.tekstData.filter ( c => c.id == id );

                //         if ( d.length > 0 )
                //         {
                //                     context.commit ( 'setTekstKeuze', id );

                //                     // de eerste in de lijst met die selectie is de goede

                //                     if ( d [ 0 ].naam !== undefined )
                //                     {
                //                         context.commit ( 'setTekstData', d [ 0 ] );

                //                         return;
                //                     }
                //         }

                //         // niet gevonden dan gewoon de eerste

                //          if ( this.state.tekstData.length > 0 )
                //          {
                //                 context.commit ( 'setTekstData',         this.state.tekstData [ 0 ] );
                //                 context.commit ( 'setTekstKeuze',     this.state.tekstData [ 0 ].id );
                //          }
                // },






  },

  modules: {
  }
})
