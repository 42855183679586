<template>
    <Schilderijen />
</template>



<script>


import Schilderijen from '@/components/Schilderijen.vue'

import store   from '@/store' ;



export default {

      name: 'Werk',

      components: {

              Schilderijen
     },



    setup ( ) {

        // const event = reactive ({


        // });

            function initPaginaData () {

                     //store.dispatch ( 'setMenuRubriekenKeuze', 77 );
                     store.dispatch ( 'setMenuStatus', 'close' );  

                     store.dispatch ( 'laadVertaling' );   

                     store.dispatch ( 'laadTekst' );   


                     //-----------------------------------//

                     let t = "Bas Lobik Paintings";

                     if ( store.state.vertaalLijst.length > 0 ) {

                            t =  store.getters.getVertaling ( "pagina_paintings_titel" );
                     }  

                     document.title = t;


                     //-----------------------------------//
            }



            return { 
                  // event, 
                   initPaginaData,

            };
       },


        beforeMount() {

                  this.initPaginaData();
        },


          mounted () {

               //  console.log( "Rubriek: mounted" );

                 this.initPaginaData();
          },


          updated () {

               this.initPaginaData();

             //  window.scrollTo(0,0);
          },

}

</script>
