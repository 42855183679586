<template>

          <figure
                     v-lazyload
                     class="image_wrapper">

                <img
                        v-lazyload
                         class="image__item"
                         :data-url="source"
                         alt="loading image"
                >

                <p
                         v-if="bijschrift != '' "
                         >
                         <span v-html="bijschrift"></span>
                </p>




          </figure>

</template>





<script>

/*

                 <p 
                    v-else 
                    class="leeg"
                    >
                </p>


                         v-lazyload 



*/



// import { 
//     reactive, 
//     computed 
// } from 'vue';




const MyDirective = {

              // created (  ) { //el, binding, vnode, prevVnode
              // },


              beforeMount( el ) {

                     //console.log( "MyDirective.beforeMount" );


                    function loadImage() {

                      //  console.log( "MyDirective.loadImage" );


                                 const imageElement = Array.from ( el.children ).find( el => el.nodeName === "IMG" );

                                 if (imageElement) {

                                                    imageElement.addEventListener("load", () => {

                                                    setTimeout(() => el.classList.add ( "loaded" ), 100 );
                                        });

                                        imageElement.addEventListener("error", () => console.log("error"));

                                        imageElement.src = imageElement.dataset.url;
                              }
                    }




                    function handleIntersect(entries, observer) {

                       // console.log( "MyDirective.handleIntersect" );

                      entries.forEach(entry => {

                            if ( entry.isIntersecting ) {

                                      loadImage();
                                      observer.unobserve ( el );
                            }
                      });
                    }

    

                    function createObserver() {

                       // console.log( "MyDirective.createObserver" );


                          const options = {
                            root: null,
                            threshold: "0"
                          };
                          const observer = new IntersectionObserver(handleIntersect, options);
                          observer.observe(el);
                     }

                    createObserver();
              },


              mounted() {},

              beforeUpdate() {}, // new

              updated() {},

              beforeUnmount() {}, // new

              unmounted() {}
}









export default {

     name: 'ImageItem',


     directives: {
              lazyload: MyDirective
     },

     components: {
      //      LoadingImg

     },

     props: {

             source: {

                     type:              String,
                     required:     true
             },

             bijschrift: {

                     type:              String,
                     required:     false
             }            
     },

};



</script>




<style scoped>



.image_wrapper
{
    margin:  0;
    padding:  0;

    opacity:  0;

    text-align: center;

    line-height: 0;
}


.image_wrapper.loaded
{
        min-height: 0rem;



        -webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
                animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;



/*         animation: beeld_fade linear 1s;
         animation-timing-function: ease;
         animation-iteration-count: 1;
         transform-origin: 50% 50%;
         animation-fill-mode: forwards;     */
}


/* ----------------------------------------------
 * Generated by Animista on 2022-12-20 14:4:43
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}



@keyframes beeld_fade {

        0% {
/*             transform: scale(.8, 1 );
*/              opacity: 0;
      }

        100% {
/*                transform: scale(1, 1);
*/                opacity: 1;
        }  
}




.image_wrapper p
{
/*            display: block;

            margin:  0 0 1rem 0;
            padding:  .2rem .5rem .5rem .5rem;

            font-size: 1rem;
            line-height: 1.1em;

            color:  rgb( 160, 160, 160 );*/
   
}


.image_wrapper p.leeg
{
        border:  none;
}


.image_wrapper img
{
        display: inline-block;

        margin:  0;
        padding:  0;

        max-width:  100%;
}








@media (min-width: 60rem) 
{
        .image_wrapper
        {
            margin:  0 0 0 0;

            line-height: 0;
        }

        .image_wrapper.loaded
        {
                min-height: 0rem;

                 animation: beeld_fade linear 1s;
                 animation-timing-function: ease;
                 animation-iteration-count: 1;
                 transform-origin: 50% 50%;
                 animation-fill-mode: forwards;     
        }



/*        .image_wrapper img
        {
                max-height: calc( 100vh - 14rem) ;
        }*/



/*        .image_wrapper p
        {
                font-size: 1.1rem;
                line-height: 1.2em;
        }*/




        @keyframes beeld_fade {

                0% {
/*                     transform: scale(.95, 1 );
*/                      opacity: .5;
              }

                100% {
/*                        transform: scale(1, 1);
*/                        opacity: 1;
                }  
        }
}






</style>
